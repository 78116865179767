import { Card, CardContent, Typography, Button, Box } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { APP_CONFIG } from '../../config/constants';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine
} from 'recharts';
import { format, startOfDay, endOfDay } from 'date-fns';
import { ja } from 'date-fns/locale';

interface MetricsChartProps {
  data: {
    time: string;
    battery_voltage_mV?: number;
    solar_panel_voltage_mV?: number;
    temperature?: number;
    humidity?: number;
    water_temp?: number;
    chlorophyll?: number;
    turbidity?: number;
  }[];
  type: 'power' | 'environment' | 'sensor';
  selectedDate: Date;
}

export default function MetricsChart({ data, type, selectedDate }: MetricsChartProps) {
  const formattedData = data
    .sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime())
    .map(item => {
      const date = new Date(item.time);
      return {
        time: date.getTime(),
        battery: item.battery_voltage_mV !== undefined ? item.battery_voltage_mV / 1000 : null,
        solar: item.solar_panel_voltage_mV !== undefined ? item.solar_panel_voltage_mV / 1000 : null,
        temperature: item.temperature,
        humidity: item.humidity,
        water_temp: item.water_temp,
        chlorophyll: item.chlorophyll,
        turbidity: item.turbidity
      };
    });

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Card sx={{
          bgcolor: APP_CONFIG.THEME.SURFACE_COLOR,
          border: `1px solid ${APP_CONFIG.THEME.TEXT_SECONDARY}`,
          p: 1
        }}>
          <Typography sx={{ color: APP_CONFIG.THEME.TEXT_PRIMARY }}>
            {format(new Date(label), 'HH:mm', { locale: ja })}
          </Typography>
          {payload.map((entry: any) => (
            entry.value !== null && (
              <Typography
                key={entry.name}
                sx={{ color: entry.color }}
              >
                {`${entry.name}: ${entry.value.toFixed(2)}${entry.unit}`}
              </Typography>
            )
          ))}
        </Card>
      );
    }
    return null;
  };

  const handleDownloadCSV = () => {
    try {
      // CSVヘッダーの作成
      let headers: string[] = ['時刻'];
      let rows: string[][] = [headers];

      if (type === 'power') {
        headers.push('バッテリー電圧(V)', 'ソーラーパネル電圧(V)');
        rows = [headers];
        formattedData.forEach(item => {
          rows.push([
            format(new Date(item.time), 'HH:mm:ss'),
            item.battery?.toFixed(2) ?? '',
            item.solar?.toFixed(2) ?? ''
          ]);
        });
      } else if (type === 'environment') {
        headers.push('温度(℃)', '湿度(%)');
        rows = [headers];
        formattedData.forEach(item => {
          rows.push([
            format(new Date(item.time), 'HH:mm:ss'),
            item.temperature?.toFixed(1) ?? '',
            item.humidity?.toFixed(1) ?? ''
          ]);
        });
      } else if (type === 'sensor') {
        headers.push('水温(℃)', 'クロロフィル(ppb)', '濁度(FTU)');
        rows = [headers];
        formattedData.forEach(item => {
          rows.push([
            format(new Date(item.time), 'HH:mm:ss'),
            item.water_temp?.toFixed(1) ?? '',
            item.chlorophyll?.toFixed(1) ?? '',
            item.turbidity?.toFixed(1) ?? ''
          ]);
        });
      }

      // CSVデータの作成
      const csvContent = rows.map(row => row.join(',')).join('\n');
      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `${type}_data_${format(selectedDate, 'yyyyMMdd')}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('CSVダウンロードエラー:', error);
    }
  };

  return (
    <Card sx={{
      background: `linear-gradient(135deg, ${APP_CONFIG.THEME.SURFACE_COLOR} 0%, #2a3042 100%)`,
      boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
      borderRadius: 2,
      p: { xs: 0.5, sm: 2 },
      height: '100%',
      minHeight: 400,
      width: '100%'
    }}>
      <CardContent sx={{ 
        p: { xs: 0.5, sm: 2 },
        '&:last-child': { pb: { xs: 1, sm: 2 } }
      }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 2,
          px: { xs: 1, sm: 0 }
        }}>
          <Typography variant="h6" sx={{ color: APP_CONFIG.THEME.PRIMARY_COLOR }}>
            {type === 'power' ? '電源情報の推移' : type === 'environment' ? '環境情報の推移' : '水質センサーデータの推移'}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
            <Button
              variant="outlined"
              startIcon={<FileDownloadIcon />}
              onClick={handleDownloadCSV}
              disabled={formattedData.length === 0}
            >
              CSV
            </Button>
          </Box>
        </Box>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={formattedData}
            margin={{ 
              top: 5, 
              right: 30,
              left: 10,
              bottom: 5 
            }}
          >
            <CartesianGrid 
              strokeDasharray="3 3" 
              stroke={`${APP_CONFIG.THEME.TEXT_SECONDARY}20`}
            />
            <XAxis
              dataKey="time"
              tickFormatter={(time: number) => format(new Date(time), 'HH:mm', { locale: ja })}
              stroke={APP_CONFIG.THEME.TEXT_SECONDARY}
              domain={[
                startOfDay(selectedDate).getTime(),
                endOfDay(selectedDate).getTime()
              ]}
              type="number"
              scale="time"
              padding={{ left: 20, right: 20 }}
              ticks={Array.from({ length: 13 }, (_, i) => {
                const date = new Date(selectedDate);
                date.setHours(i * 2, 0, 0, 0);
                return date.getTime();
              })}
            />
            {type === 'power' ? (
              <>
                <YAxis 
                  stroke={APP_CONFIG.THEME.TEXT_SECONDARY}
                  domain={[10, 15]}
                  unit="V"
                  ticks={[10, 11, 12, 13, 14, 15]}
                />
                {/* <ReferenceLine 
                  y={APP_CONFIG.DEVICE_METRICS.BATTERY_VOLTAGE.MIN / 1000} 
                  stroke={APP_CONFIG.THEME.PRIMARY_COLOR}
                  strokeDasharray="3 3"
                  label={{ value: 'OFF電圧', fill: APP_CONFIG.THEME.PRIMARY_COLOR, position: 'center' }}
                /> */}
                <Line
                  type="monotone"
                  dataKey="battery"
                  name="Battery"
                  stroke={APP_CONFIG.THEME.PRIMARY_COLOR}
                  unit="V"
                  dot={{ r: 3, fill: APP_CONFIG.THEME.PRIMARY_COLOR, stroke: APP_CONFIG.THEME.PRIMARY_COLOR }}
                  activeDot={{ r: 6, fill: APP_CONFIG.THEME.PRIMARY_COLOR, stroke: APP_CONFIG.THEME.PRIMARY_COLOR }}
                />
                {/*
                <Line
                  type="monotone"
                  dataKey="solar"
                  name="Solar"
                  stroke="#ffd700"
                  unit="V"
                  dot={{ r: 3, fill: "#ffd700", stroke: "#ffd700" }}
                  activeDot={{ r: 6, fill: "#ffd700", stroke: "#ffd700" }}
                />
                */}
              </>
            ) : type === 'environment' ? (
              <>
                <YAxis 
                  yAxisId="temperature"
                  stroke="#ff4444"
                  domain={[-10, 40]}
                  unit="℃"
                  ticks={[-10, 0, 10, 20, 30, 40]}
                />
                <YAxis 
                  yAxisId="humidity"
                  orientation="right"
                  stroke="#4444ff"
                  domain={[
                    APP_CONFIG.DEVICE_METRICS.HUMIDITY.MIN,
                    APP_CONFIG.DEVICE_METRICS.HUMIDITY.MAX
                  ]}
                  unit="%"
                />
                <Line
                  type="monotone"
                  dataKey="temperature"
                  name="温度"
                  yAxisId="temperature"
                  stroke="#ff4444"
                  unit="℃"
                  dot={{ r: 3, fill: "#ff4444", stroke: "#ff4444" }}
                  activeDot={{ r: 6, fill: "#ff4444", stroke: "#ff4444" }}
                />
                <Line
                  type="monotone"
                  dataKey="humidity"
                  name="湿度"
                  yAxisId="humidity"
                  stroke="#4444ff"
                  unit="%"
                  dot={{ r: 3, fill: "#4444ff", stroke: "#4444ff" }}
                  activeDot={{ r: 6, fill: "#4444ff", stroke: "#4444ff" }}
                />
              </>
            ) : (
              <>
                <YAxis 
                  yAxisId="water_temp"
                  stroke="#2196f3"
                  domain={[0, 30]}
                  unit="℃"
                />
                <YAxis 
                  yAxisId="chlorophyll"
                  orientation="right"
                  stroke="#4caf50"
                  domain={[0, 5]}
                  unit="ppb"
                />
                <YAxis 
                  yAxisId="turbidity"
                  orientation="right"
                  stroke="#ff9800"
                  domain={[0, 10]}
                  unit="FTU"
                />
                {/* <YAxis 
                  yAxisId="dissolved_oxygen"
                  orientation="right"
                  stroke="#ff9800"
                  domain={[0, 15]}
                  unit="mg/L"
                /> */}
                <Line
                  type="monotone"
                  dataKey="water_temp"
                  name="水温"
                  yAxisId="water_temp"
                  stroke="#2196f3"
                  unit="℃"
                  dot={{ r: 3, fill: "#2196f3", stroke: "#2196f3" }}
                  activeDot={{ r: 6, fill: "#2196f3", stroke: "#2196f3" }}
                />
                <Line
                  type="monotone"
                  dataKey="chlorophyll"
                  name="クロロフィル"
                  yAxisId="chlorophyll"
                  stroke="#4caf50"
                  unit="ppb"
                  dot={{ r: 3, fill: "#4caf50", stroke: "#4caf50" }}
                  activeDot={{ r: 6, fill: "#4caf50", stroke: "#4caf50" }}
                />
                <Line
                  type="monotone"
                  dataKey="turbidity"
                  name="濁度"
                  yAxisId="turbidity"
                  stroke="#ff9800"
                  unit="FTU"
                  dot={{ r: 3, fill: "#ff9800", stroke: "#ff9800" }}
                  activeDot={{ r: 6, fill: "#ff9800", stroke: "#ff9800" }}
                />
              </>
            )}
            <Tooltip content={<CustomTooltip />} />
            <Legend 
              wrapperStyle={{ 
                color: APP_CONFIG.THEME.TEXT_PRIMARY 
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
