import { useState, useEffect, useCallback, useMemo, memo, forwardRef, useImperativeHandle } from 'react';
import { S3Client, ListObjectsV2Command, GetObjectCommand } from '@aws-sdk/client-s3';
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Button,
  Card,
  CardContent,
  Grid,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import ja from 'date-fns/locale/ja';
import { startOfDay, endOfDay, format } from 'date-fns';
import { APP_CONFIG } from '../../config/constants';
import { commonSelectStyles, filterContainerStyles } from '../../styles/commonStyles';
import MetricsChart from '../common/MetricsChart';
import { TrendingUp, TrendingDown, TrendingFlat } from '@mui/icons-material';
import NoDataMessage from '../common/NoDataMessage';

interface SensorData {
  sensor_data: string;
  metrics_data: {
    CH1_voltage_mV: number;
    CH2_voltage_mV: number;
    temperature: number;
    humidity: number;
    GNSS: any;
    errors?: string[];
    lte: any;
    acceleration: {
      x: number | null;
      y: number | null;
      z: number | null;
    };
    device_settings: any;
  };
}

interface ParsedSensorData {
  water_temp: number | null;
  turbidity: number | null;
  chlorophyll: number | null;
  voltage: number | null;
  status: number | null;
}

interface SensorValues {
  water_temp: number | null;
  turbidity: number | null;
  chlorophyll: number | null;
  error?: string;
}

const parseSensorData = (rawData: string): ParsedSensorData | null => {
  if (!rawData) return null;
  
  try {
    const cleanData = rawData.replace(/\u0000/g, '');
    const match = cleanData.match(/=\d+,PVAL,(.+?)\r?$/);
    if (!match) return null;
    
    const values = match[1].split(',').map(v => {
      const parsed = parseFloat(v);
      return isNaN(parsed) ? null : parsed;
    });
    
    return {
      water_temp: values[0],
      turbidity: values[1],
      chlorophyll: values[2],
      voltage: values[3],
      status: values[4]
    };
  } catch (error) {
    console.error('センサーデータのパースエラー:', error, 'rawData:', rawData);
    return null;
  }
};

const getSensorValues = (data: SensorData | undefined) => {
  if (!data) {
    return {
      water_temp: null,
      turbidity: null,
      chlorophyll: null,
      error: 'データが存在しません'
    };
  }

  if (!data.sensor_data || data.sensor_data.trim() === '') {
    return {
      water_temp: null,
      turbidity: null,
      chlorophyll: null,
      error: 'センサーデータが空です'
    };
  }

  const parsedData = parseSensorData(data.sensor_data);
  if (!parsedData) {
    console.error('パースに失敗したセンサーデータ:', data.sensor_data);
    return {
      water_temp: null,
      turbidity: null,
      chlorophyll: null,
      error: 'データの解析に失敗しました'
    };
  }

  // すべての値がnullの場合はエラーメッセージを返す
  if (parsedData.water_temp === null && 
      parsedData.turbidity === null && 
      parsedData.chlorophyll === null) {
    return {
      water_temp: null,
      turbidity: null,
      chlorophyll: null,
      error: 'センサーデータが取得できませんでした'
    };
  }

  return {
    water_temp: parsedData.water_temp,
    turbidity: parsedData.turbidity,
    chlorophyll: parsedData.chlorophyll,
    error: undefined
  };
};

interface SensorCardProps {
  title: string;
  value: number | null;
  unit: string;
  trend: 'up' | 'down' | 'flat';
  gradient: string;
  sensorData: SensorData | null;
  availableData: { time: string; data: SensorData }[];
  getTrendIcon: (trend: 'up' | 'down' | 'flat', color: string) => JSX.Element;
  memoizedGetTrend: (current: number, previous: number | undefined) => 'up' | 'down' | 'flat';
}

const SensorCard = memo(({ title, value, unit, trend, gradient, sensorData, availableData, getTrendIcon, memoizedGetTrend }: SensorCardProps) => {
  const sensorValues = sensorData ? getSensorValues(sensorData) : null;

  // トレンド計算用の値を取得
  const getTrendValue = (data: SensorData | null): number | undefined => {
    if (!data) return undefined;
    const values = getSensorValues(data);
    if (values.error) return undefined;

    switch (title) {
      case '水温':
        return values.water_temp ?? undefined;
      case 'クロロフィル':
        return values.chlorophyll ?? undefined;
      case '濁度':
        return values.turbidity ?? undefined;
      default:
        return undefined;
    }
  };

  const currentValue = getTrendValue(sensorData);
  const previousValue = getTrendValue(availableData[1]?.data);

  return (
    <Card sx={{
      background: `linear-gradient(135deg, ${APP_CONFIG.THEME.SURFACE_COLOR} 0%, ${gradient} 100%)`,
      borderRadius: 2,
      boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
      backdropFilter: 'blur(4px)',
      border: '1px solid rgba(255, 255, 255, 0.18)',
      position: 'relative',
      overflow: 'hidden',
    }}>
      <CardContent>
        <Box sx={{ position: 'relative', zIndex: 1, p: 1 }}>
          <Typography variant="overline" sx={{ color: 'rgba(255,255,255,0.7)', letterSpacing: 1, fontWeight: 500, fontSize: '1.1rem', textTransform: 'none' }}>
            {title}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
            <Typography variant="h3" sx={{ color: '#fff', fontWeight: 600, textShadow: '2px 2px 4px rgba(0,0,0,0.2)' }}>
              {value?.toFixed(1) ?? '---'}
              <Typography component="span" variant="h6" sx={{ ml: 1, color: 'rgba(255,255,255,0.7)' }}>
                {unit}
              </Typography>
            </Typography>
            {currentValue !== undefined && previousValue !== undefined && !sensorValues?.error && (
              <Box sx={{ transform: 'scale(1.5)' }}>
                {getTrendIcon(
                  memoizedGetTrend(currentValue, previousValue),
                  '#fff'
                )}
              </Box>
            )}
          </Box>
          {sensorValues?.error && (
            <Typography color="error" sx={{ mt: 2, fontSize: '0.875rem' }}>
              {sensorValues.error}
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
});

interface SensorDataWithTime {
  time: string;
  originalTime: string;
  data: SensorData;
}

interface SensorTabProps {
  deviceId: string;
}

interface ChartDataPoint {
  time: string;
  water_temp?: number;
  turbidity?: number;
  chlorophyll?: number;
  battery_voltage_mV?: number;
}

const SensorTab = forwardRef<{ handleRefresh: () => Promise<void> }, SensorTabProps>(({ deviceId }, ref) => {
  const [sensorData, setSensorData] = useState<SensorData | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [availableData, setAvailableData] = useState<SensorDataWithTime[]>([]);

  const s3Client = useMemo(() => new S3Client({
    region: APP_CONFIG.AWS_CONFIG.REGION,
    credentials: {
      accessKeyId: APP_CONFIG.AWS_CONFIG.ACCESS_KEY_ID!,
      secretAccessKey: APP_CONFIG.AWS_CONFIG.SECRET_ACCESS_KEY!,
    },
  }), []);

  // 時刻データの有効性をチェックする関数を修正
  const validateTimeData = useCallback((data: SensorDataWithTime[]) => {
    return data.filter(item => {
      try {
        const timeDate = new Date(`1970-01-01T${item.time}`);
        // sensor_dataが空でないことを確認
        const hasSensorData = item.data.sensor_data && item.data.sensor_data.trim() !== '';
        return !isNaN(timeDate.getTime()) && hasSensorData;
      } catch {
        console.error('Invalid time format or missing sensor data:', item.time);
        return false;
      }
    });
  }, []);

  const fetchSensorData = useCallback(async (date: Date) => {
    if (!deviceId) return;
    setLoading(true);
    setError('');
    try {
      const dayStart = startOfDay(date);
      const dayEnd = endOfDay(date);
      
      const listCommand = new ListObjectsV2Command({
        Bucket: APP_CONFIG.S3_CONFIG.BUCKET_NAME,
        Prefix: `${APP_CONFIG.S3_CONFIG.BASE_PATH}/${deviceId}/${APP_CONFIG.PATHS.JSON}/`,
        MaxKeys: 1000
      });
      
      let allContents: any[] = [];
      let continuationToken: string | undefined;

      do {
        const result = await s3Client.send(listCommand);
        if (result.Contents) {
          allContents = [...allContents, ...result.Contents];
        }
        continuationToken = result.NextContinuationToken;
        if (continuationToken) {
          listCommand.input.ContinuationToken = continuationToken;
        }
      } while (continuationToken);

      if (allContents.length === 0) {
        setAvailableData([]);
        setSensorData(null);
        setSelectedTime('');
        return;
      }

      const filteredContents = allContents
        .filter(item => {
          if (!item.LastModified) return false;
          const itemDate = new Date(item.LastModified);
          return itemDate >= dayStart && itemDate <= dayEnd;
        })
        .sort((a, b) => {
          const dateA = new Date(a.LastModified!).getTime();
          const dateB = new Date(b.LastModified!).getTime();
          return dateB - dateA;
        });

      const dataPromises = filteredContents.map(async (item) => {
        if (!item.Key) return null;
        try {
          const getCommand = new GetObjectCommand({
            Bucket: APP_CONFIG.S3_CONFIG.BUCKET_NAME,
            Key: item.Key,
          });
          const response = await s3Client.send(getCommand);
          const jsonData = await response.Body?.transformToString();
          if (jsonData) {
            const data = JSON.parse(jsonData);
            const lastModified = new Date(item.LastModified!);
            return {
              time: lastModified.toLocaleTimeString('ja-JP', {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false
              }),
              originalTime: lastModified.toISOString(),
              data: data as SensorData
            };
          }
        } catch (err) {
          console.error(`Error getting data for ${item.Key}:`, err);
        }
        return null;
      });

      const validData = validateTimeData(
        (await Promise.all(dataPromises))
          .filter((item): item is SensorDataWithTime => item !== null)
      );

      if (validData.length > 0) {
        setAvailableData(validData);
        const latestTime = validData[0].time;
        setSelectedTime(latestTime);
        setSensorData(validData[0].data);
      } else {
        setAvailableData([]);
        setSelectedTime('');
        setSensorData(null);
      }

    } catch (err) {
      console.error('Error fetching sensor data:', err);
      setError(`センサーデータの取得に失敗しました: ${(err as any).message}`);
      setAvailableData([]);
      setSelectedTime('');
      setSensorData(null);
    } finally {
      setLoading(false);
    }
  }, [deviceId, s3Client, validateTimeData]);

  const fetchAvailableDates = useCallback(async () => {
    try {
      const listCommand = new ListObjectsV2Command({
        Bucket: APP_CONFIG.S3_CONFIG.BUCKET_NAME,
        Prefix: `${APP_CONFIG.S3_CONFIG.BASE_PATH}/${deviceId}/${APP_CONFIG.PATHS.JSON}/`,
        MaxKeys: 1000
      });

      let allContents: any[] = [];
      let continuationToken: string | undefined;

      do {
        const result = await s3Client.send(listCommand);
        if (result.Contents) {
          allContents = [...allContents, ...result.Contents];
        }
        continuationToken = result.NextContinuationToken;
        if (continuationToken) {
          listCommand.input.ContinuationToken = continuationToken;
        }
      } while (continuationToken);

      if (allContents.length > 0) {
        const dates = allContents
          .filter(item => item.LastModified)
          .map(item => new Date(item.LastModified!).toISOString().split('T')[0])
          .sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
        
        const uniqueDates = Array.from(new Set(dates));
        setAvailableDates(uniqueDates);
        return uniqueDates;
      }
      return [];
    } catch (err) {
      console.error('Error fetching available dates:', err);
      return [];
    }
  }, [s3Client, deviceId]);

  const handleDateChange = useCallback((date: Date | null) => {
    if (date) {
      setSelectedDate(date);
      setSelectedTime('');
      fetchSensorData(date);
    }
  }, [fetchSensorData]);

  const handleTimeChange = useCallback((event: SelectChangeEvent<string>) => {
    const time = event.target.value;
    if (!time) return;

    setSelectedTime(time);
    const selectedData = availableData.find(item => item.time === time);
    if (selectedData?.data) {
      setSensorData(selectedData.data);
    }
  }, [availableData]);

  const handleRefresh = useCallback(async () => {
    if (!deviceId) return;
    setLoading(true);
    setError('');
    try {
      const now = new Date();
      const today = startOfDay(now);
      
      await fetchSensorData(today);
      
      if (availableData.length === 0) {
        const dates = await fetchAvailableDates();
        if (dates.length > 0) {
          const latestDate = new Date(dates[0]);
          setSelectedDate(latestDate);
          await fetchSensorData(latestDate);
        }
      }
    } catch (err) {
      console.error('センサーデータの更新に失敗しました:', err);
      setError(`データの更新に失敗しました: ${(err as any).message}`);
    } finally {
      setLoading(false);
    }
  }, [deviceId, fetchSensorData, fetchAvailableDates, availableData.length]);

  useEffect(() => {
    if (deviceId) {
      fetchAvailableDates();
    }
  }, [deviceId, fetchAvailableDates]);

  useEffect(() => {
    if (deviceId && selectedDate) {
      fetchSensorData(selectedDate);
    }
  }, [deviceId, selectedDate, fetchSensorData]);

  useImperativeHandle(ref, () => ({
    handleRefresh
  }), [handleRefresh]);

  const getTrend = (current: number, previous: number | undefined): 'up' | 'down' | 'flat' => {
    if (previous === undefined) return 'flat';
    const diff = current - previous;
    if (Math.abs(diff) < 0.1) return 'flat';
    return diff > 0 ? 'up' : 'down';
  };

  const getTrendIcon = (trend: 'up' | 'down' | 'flat', color: string) => {
    switch (trend) {
      case 'up':
        return <TrendingUp sx={{ color }} />;
      case 'down':
        return <TrendingDown sx={{ color }} />;
      default:
        return <TrendingFlat sx={{ color }} />;
    }
  };

  const memoizedGetTrend = useMemo(() => {
    return (current: number, previous: number | undefined): 'up' | 'down' | 'flat' => getTrend(current, previous);
  }, []);

  const memoizedGetSensorValues = useMemo(() => {
    return (data: SensorData | undefined) => getSensorValues(data);
  }, []);

  const chartData = useMemo(() => {
    const validData: ChartDataPoint[] = [];
    
    for (const item of availableData) {
      try {
        const sensorValues = memoizedGetSensorValues(item?.data);
        const itemDate = new Date(item.originalTime);
        
        if (isNaN(itemDate.getTime())) {
          console.error('Invalid date:', item.originalTime);
          continue;
        }

        validData.push({
          time: itemDate.toISOString(),
          water_temp: sensorValues.water_temp ?? undefined,
          turbidity: sensorValues.turbidity ?? undefined,
          chlorophyll: sensorValues.chlorophyll ?? undefined,
          battery_voltage_mV: item.data.metrics_data.CH1_voltage_mV
        });
      } catch (error) {
        console.error('Error processing chart data:', error);
      }
    }

    return validData;
  }, [availableData, memoizedGetSensorValues]);

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6">水質センサーデータ</Typography>
        <Button 
          variant="contained" 
          onClick={handleRefresh}
          disabled={loading}
        >
          更新
        </Button>
      </Box>

      <Box sx={filterContainerStyles}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
          <DatePicker
            label="日付でフィルター"
            value={selectedDate}
            onChange={handleDateChange}
            shouldDisableDate={(date) => !availableDates.includes(date.toISOString().split('T')[0])}
            sx={commonSelectStyles}
          />
        </LocalizationProvider>
        <Select
          value={selectedTime}
          onChange={handleTimeChange}
          sx={commonSelectStyles}
          displayEmpty
          disabled={loading || availableData.length === 0}
        >
          <MenuItem value="" disabled>
            時刻を選択
          </MenuItem>
          {availableData.map((item) => (
            <MenuItem key={item.originalTime} value={item.time}>
              {item.time}
            </MenuItem>
          ))}
        </Select>
      </Box>

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {!loading && !error && (!sensorData || availableData.length === 0) && (
        <NoDataMessage />
      )}

      {!loading && !error && sensorData && availableData.length > 0 && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <SensorCard
              title="水温"
              value={memoizedGetSensorValues(sensorData).water_temp}
              unit="℃"
              trend={memoizedGetTrend(
                memoizedGetSensorValues(sensorData).water_temp ?? 0,
                memoizedGetSensorValues(availableData[1]?.data)?.water_temp ?? undefined
              )}
              gradient={APP_CONFIG.THEME.SURFACE_COLOR}
              sensorData={sensorData}
              availableData={availableData}
              getTrendIcon={getTrendIcon}
              memoizedGetTrend={memoizedGetTrend}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <SensorCard
              title="クロロフィル"
              value={memoizedGetSensorValues(sensorData).chlorophyll}
              unit="ppb"
              trend={memoizedGetTrend(
                memoizedGetSensorValues(sensorData).chlorophyll ?? 0,
                memoizedGetSensorValues(availableData[1]?.data)?.chlorophyll ?? undefined
              )}
              gradient="#1b5e20"
              sensorData={sensorData}
              availableData={availableData}
              getTrendIcon={getTrendIcon}
              memoizedGetTrend={memoizedGetTrend}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <SensorCard
              title="濁度"
              value={memoizedGetSensorValues(sensorData).turbidity}
              unit="FTU"
              trend={memoizedGetTrend(
                memoizedGetSensorValues(sensorData).turbidity ?? 0,
                memoizedGetSensorValues(availableData[1]?.data)?.turbidity ?? undefined
              )}
              gradient="#e65100"
              sensorData={sensorData}
              availableData={availableData}
              getTrendIcon={getTrendIcon}
              memoizedGetTrend={memoizedGetTrend}
            />
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ 
              width: '100%',
              overflow: 'auto',
              '& .recharts-wrapper': {
                width: '100% !important',
                minWidth: { xs: '400px', sm: '100%' },
                '& .recharts-surface': {
                  width: '100%',
                }
              }
            }}>
              <MetricsChart
                data={chartData}
                type="sensor"
                selectedDate={selectedDate}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
});

export default SensorTab;
